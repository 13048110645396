<template>
  <v-container>
    <v-toolbar outlined flat rounded>
      <TermTabs v-model="term" /><v-spacer /><PeopleSort @update="fetchData" />
    </v-toolbar>
    <v-data-table
      dense
      :loading="loading"
      :headers="headers"
      :items="items"
      :item-class="(item) => item.class"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.student="{ item }">
        <PersonItem :value="item.student" />
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { saveChildRoute } from "@/router/helper";
import { comparePeople } from "common/utils/people.js";
import PeopleSort from "@/components/settings/PeopleSort";
import PersonItem from "@/components/PersonItem";
import TermTabs from "@/components/TermTabs";

function colorRow(warningLevel) {
  switch (warningLevel) {
    case 1:
      return "yellow lighten-4";
    case 2:
      return "red lighten-4";
    default:
      return "";
  }
}
export default {
  name: "SchoolClassAbsences",
  components: {
    PersonItem,
    PeopleSort,
    TermTabs,
  },
  props: {
    id: { type: String },
  },
  computed: {
    schoolClassId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      headers: [
        { text: "Schüler*in", value: "student" },
        { text: "pendent", value: "pendingLessons", align: "right" },
        { text: "entschuldigt", value: "excusedLessons", align: "right" },
        { text: "unentschuldigt", value: "unexcusedLessons", align: "right" },
      ],
      items: [],
      loading: false,
      term: null,
    };
  },
  methods: {
    async fetchData() {
      if (this.schoolClassId && this.term) {
        this.loading = true;
        let query = `schoolClass=${this.schoolClassId}`;
        if (this.term) query += `&term=${this.term.id}`;
        this.items = await this.apiList({
          resource: "absence/overview",
          query: query,
        });
        this.items.forEach((item) => {
          item.class = colorRow(item.warningLevel);
        });
        this.sortPeople();
        this.loading = false;
      }
    },
    sortPeople() {
      this.items = this.items.slice().sort((first, second) => {
        return comparePeople(first.student, second.student);
      });
    },
  },
  watch: {
    async id() {
      await this.fetchData();
    },
    async term() {
      await this.fetchData();
    },
  },
  async created() {
    await this.fetchData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
};
</script>
